import React from 'react';
import MainHeader from "../../headers/MainHeader/MainHeader";
import MainFooter from "../../Footer/MainFooter/MainFooter";

const MainLayout = ({children}) => {
    return (
        <>
            <MainHeader></MainHeader>
            <main>{children}</main>
            <MainFooter></MainFooter>
        </>
    );
};

export default MainLayout;
import React, {useEffect, useState} from "react";
import { useLocation } from "react-router-dom";
import styles from './PdfViewerPage.module.scss';
import { Document, Page } from "react-pdf";
import { pdfjs } from 'react-pdf';

pdfjs.GlobalWorkerOptions.workerSrc = new URL(
    'https://cdnjs.cloudflare.com/ajax/libs/pdf.js/4.8.69/pdf.worker.min.mjs',
    import.meta.url,
).toString();

const PdfViewerPage = () => {
    const location = useLocation();
    const { pdfUrl } = location.state;

    const proxyUrl = `${pdfUrl}`;

    const [error, setError] = useState(null);
    const [numPages, setNumPages] = useState(null);
    const [currentPage, setCurrentPage] = useState(1);
    const [loading, setLoading] = useState(true);

    const onDocumentLoadSuccess = ({ numPages }) => {
        setNumPages(numPages);
        setLoading(false);
    };

    const onLoadError = (error) => {
        console.error("Ошибка при загрузке PDF:", error);
        setError("Не удалось загрузить PDF файл. Пожалуйста, проверьте ссылку или настройки сервера.");
        setLoading(false);
    };

    const goToNextPage = () => {
        if (currentPage < numPages) setCurrentPage((prevPage) => prevPage + 1);
    };

    const goToPrevPage = () => {
        if (currentPage > 1) setCurrentPage((prevPage) => prevPage - 1);
    };

    return (
        <div className={styles.pdfViewer}>
            {error ? (
                <div className={styles.error}>{error}</div>
            ) : (
                <>
                    <div className={styles.viewer}>
                        <Document
                            file={proxyUrl}
                            onLoadSuccess={onDocumentLoadSuccess}
                            onLoadError={onLoadError}
                        >
                            <Page
                                renderTextLayer={false}
                                renderAnnotationLayer={false}
                                pageNumber={currentPage} />
                        </Document>
                    </div>
                    <div className={styles.pagination}>
                        <button onClick={goToPrevPage} disabled={currentPage === 1}>
                            Назад
                        </button>
                        <span>
                            Страница {currentPage} из {numPages}
                        </span>
                        <button onClick={goToNextPage} disabled={currentPage === numPages}>
                            Вперед
                        </button>
                    </div>
                </>
            )}
        </div>
    );
};

export default PdfViewerPage;
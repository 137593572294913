// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.PdfViewerPage_pdfViewer__XfBFT {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 20px;
  padding: 20px;
  background-color: #f9f9f9;
  min-height: 100vh;
}
.PdfViewerPage_pdfViewer__XfBFT h1 {
  font-size: 24px;
  color: #333;
}
.PdfViewerPage_pdfViewer__XfBFT .PdfViewerPage_error__qQTX3 {
  color: #ff0000;
  font-size: 16px;
  text-align: center;
}
.PdfViewerPage_pdfViewer__XfBFT .PdfViewerPage_loading__h2XaO {
  color: #555;
  font-size: 16px;
  text-align: center;
}
.PdfViewerPage_pdfViewer__XfBFT .PdfViewerPage_viewer__yk9MC {
  width: 90%;
  max-width: 800px;
  border: 1px solid #ddd;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  background-color: #fff;
  overflow: auto;
}
.PdfViewerPage_pdfViewer__XfBFT .PdfViewerPage_viewer__yk9MC canvas {
  margin: 0 auto;
}
.PdfViewerPage_pdfViewer__XfBFT .PdfViewerPage_pagination__uIZqK {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 15px;
}
.PdfViewerPage_pdfViewer__XfBFT .PdfViewerPage_pagination__uIZqK button {
  padding: 8px 16px;
  border: none;
  border-radius: 4px;
  background-color: #1f887d;
  color: #fff;
  font-size: 14px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}
.PdfViewerPage_pdfViewer__XfBFT .PdfViewerPage_pagination__uIZqK button:disabled {
  background-color: #ccc;
  cursor: not-allowed;
}
.PdfViewerPage_pdfViewer__XfBFT .PdfViewerPage_pagination__uIZqK button:hover:not(:disabled) {
  background-color: #146b5e;
}
.PdfViewerPage_pdfViewer__XfBFT .PdfViewerPage_pagination__uIZqK span {
  font-size: 14px;
  color: #555;
}`, "",{"version":3,"sources":["webpack://./src/pages/PdfViewerPage/PdfViewerPage.module.scss"],"names":[],"mappings":"AAAA;EACE,aAAA;EACA,sBAAA;EACA,mBAAA;EACA,SAAA;EACA,aAAA;EACA,yBAAA;EACA,iBAAA;AACF;AACE;EACE,eAAA;EACA,WAAA;AACJ;AAEE;EACE,cAAA;EACA,eAAA;EACA,kBAAA;AAAJ;AAGE;EACE,WAAA;EACA,eAAA;EACA,kBAAA;AADJ;AAIE;EACE,UAAA;EACA,gBAAA;EACA,sBAAA;EACA,wCAAA;EACA,sBAAA;EACA,cAAA;AAFJ;AAII;EACE,cAAA;AAFN;AAME;EACE,aAAA;EACA,uBAAA;EACA,mBAAA;EACA,SAAA;AAJJ;AAMI;EACE,iBAAA;EACA,YAAA;EACA,kBAAA;EACA,yBAAA;EACA,WAAA;EACA,eAAA;EACA,eAAA;EACA,sCAAA;AAJN;AAMM;EACE,sBAAA;EACA,mBAAA;AAJR;AAOM;EACE,yBAAA;AALR;AASI;EACE,eAAA;EACA,WAAA;AAPN","sourcesContent":[".pdfViewer {\n  display: flex;\n  flex-direction: column;\n  align-items: center;\n  gap: 20px;\n  padding: 20px;\n  background-color: #f9f9f9;\n  min-height: 100vh;\n\n  h1 {\n    font-size: 24px;\n    color: #333;\n  }\n\n  .error {\n    color: #ff0000;\n    font-size: 16px;\n    text-align: center;\n  }\n\n  .loading {\n    color: #555;\n    font-size: 16px;\n    text-align: center;\n  }\n\n  .viewer {\n    width: 90%;\n    max-width: 800px;\n    border: 1px solid #ddd;\n    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);\n    background-color: #fff;\n    overflow: auto;\n\n    canvas {\n      margin: 0 auto;\n    }\n  }\n\n  .pagination {\n    display: flex;\n    justify-content: center;\n    align-items: center;\n    gap: 15px;\n\n    button {\n      padding: 8px 16px;\n      border: none;\n      border-radius: 4px;\n      background-color: #1f887d;\n      color: #fff;\n      font-size: 14px;\n      cursor: pointer;\n      transition: background-color 0.3s ease;\n\n      &:disabled {\n        background-color: #ccc;\n        cursor: not-allowed;\n      }\n\n      &:hover:not(:disabled) {\n        background-color: #146b5e;\n      }\n    }\n\n    span {\n      font-size: 14px;\n      color: #555;\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"pdfViewer": `PdfViewerPage_pdfViewer__XfBFT`,
	"error": `PdfViewerPage_error__qQTX3`,
	"loading": `PdfViewerPage_loading__h2XaO`,
	"viewer": `PdfViewerPage_viewer__yk9MC`,
	"pagination": `PdfViewerPage_pagination__uIZqK`
};
export default ___CSS_LOADER_EXPORT___;

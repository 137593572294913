import React, {useEffect, useRef, useState} from 'react';
import styles from './CatalogHeader.module.scss';
import {Link} from "react-router-dom";
import axios from "axios";

const CatalogHeader = () => {

    const [menuOpen, setMenuOpen] = useState(false);
    const [searchOpen, setSearchOpen] = useState(false);
    const [selectedNation, setSelectedNation] = useState('Русские');

    const [searchData, setSearchData] = useState(null);

    const headerKazgikRef = useRef(null);
    const [menuLeftWidth, setMenuLeftWidth] = useState(0);

    useEffect(() => {
        if (headerKazgikRef.current) {
            console.log(headerKazgikRef.current.offsetWidth)
            setMenuLeftWidth(headerKazgikRef.current.offsetWidth);
        }
    }, []);

    const handleInputChange = async (e) => {
        if (e.key === 'Enter') {
            try {
                const formData = new FormData();
                formData.append("query", e.target.value);

                const response = await axios.post(
                    "https://back.xn--80aefjyiibbhi8kta6b.xn--p1ai/api/nations/search",
                    formData
                );

                if (response.data) {
                    setSearchData(response.data); // Сохраняем только если данные существуют
                } else {
                    setSearchData(null); // Очищаем, если данных нет
                }
            } catch (error) {
                console.error("Ошибка при отправке запроса:", error);
            }
        }
    };

    // Данные для меню
    const menuData = {
        Русские: [
            { name: "Танцы", link: "/nation/russkie" },
            { name: "Игры и обряды", link: "/nation/russkie" },
            { name: "Костюмы и аксессуары", link: "/nation/russkie" },
            { name: "Видео материалы", link: "/nation/russkie" },
            { name: "Образовательные материалы", link: "/nation/russkie" },
            { name: "Песни", link: "/nation/russkie" },
            { name: "Музыкальные инструменты", link: "/nation/russkie" },
            { name: "Кухня", link: "/nation/russkie" },
            { name: "Фольклор", link: "/nation/russkie/folklor" },
            { name: "Культурные учреждения", link: "/nation/russkie" },
        ],
        Татары: [
            { name: "Танцы", link: "/nation/tatary" },
            { name: "Игры и обряды", link: "/nation/tatary" },
            { name: "Костюмы и аксессуары", link: "/nation/tatary" },
            { name: "Видео материалы", link: "/nation/tatary" },
            { name: "Образовательные материалы", link: "/nation/tatary" },
            { name: "Песни", link: "/nation/tatary/pesni" },
            { name: "Музыкальные инструменты", link: "/nation/tatary" },
            { name: "Кухня", link: "/nation/tatary/kuhnya" },
            { name: "Фольклор", link: "/nation/tatary/folklor" },
            { name: "Культурные учреждения", link: "/nation/tatary" },
        ],
        Башкиры: [
            { name: "Танцы", link: "/bsh/dances" },
            { name: "Игры и обряды", link: "/bsh/so1ngs" },
            { name: "Костюмы и аксессуары", link: "/bsh/tal5es" },
            { name: "Видео материалы", link: "/bsh/tales" },
            { name: "Образовательные материалы", link: "/bsh/tales" },
            { name: "Песни", link: "/bsh/tales" },
            { name: "Музыкальные инструменты", link: "/bsh/tales" },
            { name: "Кухня", link: "/bsh/tales" },
            { name: "Фольклор", link: "/bsh/tales" },
            { name: "Культурные учреждения", link: "/bsh/tales" },
        ],
        Чуваши: [
            { name: "Танцы", link: "/cvh/dances" },
            { name: "Игры и обряды", link: "/cvh/songs" },
            { name: "Костюмы и аксессуары", link: "/cvh/tales" },
            { name: "Видео материалы", link: "/cvh/tales" },
            { name: "Образовательные материалы", link: "/cvh/tales" },
            { name: "Песни", link: "/rus/tales" },
            { name: "Музыкальные инструменты", link: "/cvh/tales" },
            { name: "Кухня", link: "/cvh/tales" },
            { name: "Фольклор", link: "/cvh/tales" },
            { name: "Культурные учреждения", link: "/cvh/tales" },
        ],
        Мордва: [
            { name: "Танцы", link: "/mrd/dances" },
            { name: "Игры и обряды", link: "/mrd/songs" },
            { name: "Костюмы и аксессуары", link: "/mrd/tales" },
            { name: "Видео материалы", link: "/mrd/tales" },
            { name: "Образовательные материалы", link: "/mrd/tales" },
            { name: "Песни", link: "/rus/tales" },
            { name: "Музыкальные инструменты", link: "/mrd/tales" },
            { name: "Кухня", link: "/mrd/tales" },
            { name: "Фольклор", link: "/mrd/tales" },
            { name: "Культурные учреждения", link: "/mrd/tales" },
        ],
        Удмурты: [
            { name: "Танцы", link: "/ud/dances" },
            { name: "Игры и обряды", link: "/ud/songs" },
            { name: "Костюмы и аксессуары", link: "/ud/tales" },
            { name: "Видео материалы", link: "/ud/tales" },
            { name: "Образовательные материалы", link: "/ud/tales" },
            { name: "Песни", link: "/ud/tales" },
            { name: "Музыкальные инструменты", link: "/ud/tales" },
            { name: "Кухня", link: "/ud/tales" },
            { name: "Фольклор", link: "/ud/tales" },
            { name: "Культурные учреждения", link: "/ud/tales" },
        ],
        Марийцы: [
            { name: "Танцы", link: "/mar/dances" },
            { name: "Игры и обряды", link: "/mar/songs" },
            { name: "Костюмы и аксессуары", link: "/mar/tales" },
            { name: "Видео материалы", link: "/mar/tales" },
            { name: "Образовательные материалы", link: "/mar/tales" },
            { name: "Песни", link: "/mar/tales" },
            { name: "Музыкальные инструменты", link: "/mar/tales" },
            { name: "Кухня", link: "/mar/tales" },
            { name: "Фольклор", link: "/mar/tales" },
            { name: "Культурные учреждения", link: "/mar/tales" },
        ],
        "Другие народы": [
            { name: "Танцы", link: "/oth/dances" },
            { name: "Игры и обряды", link: "/oth/songs" },
            { name: "Костюмы и аксессуары", link: "/oth/tales" },
            { name: "Видео материалы", link: "/oth/tales" },
            { name: "Образовательные материалы", link: "/oth/tales" },
            { name: "Песни", link: "/oth/tales" },
            { name: "Музыкальные инструменты", link: "/oth/tales" },
            { name: "Кухня", link: "/oth/tales" },
            { name: "Фольклор", link: "/oth/tales" },
            { name: "Культурные учреждения", link: "/oth/tales" },
        ],
    };

    return (
        <div className={styles.header}>
            {/* Затемнение страницы */}
            {menuOpen && (
                <div
                    className={styles.overlay}
                    onClick={() => setMenuOpen(false)}
                />
            )}

            {/* Меню */}
            {menuOpen && (
                <div className={styles.menu}>
                    <div className={styles.menu_left}
                         style={{ minWidth: `${menuLeftWidth}px` }}
                    >
                        {Object.keys(menuData).map((nation) => (
                            <div
                                key={nation}
                                onClick={() => setSelectedNation(nation)}
                                style={{
                                    cursor: "pointer",
                                    backgroundColor: selectedNation === nation ? "#EB4046" : "white",
                                    color: selectedNation === nation ? "#FFFFFF" : "#3A3A3A",
                                }}
                            >
                                {nation}
                            </div>
                        ))}
                    </div>
                    <div className={styles.menu_right}>
                        {selectedNation && (
                            <ul>
                                {menuData[selectedNation].map((item, index) => (
                                    <Link to={item.link} key={`${item.name}-${item.link}`}>
                                        <li onClick={() => setMenuOpen(false)}>
                                            {item.name}
                                            <img src="/images/catalog/LINK_ARROW_BLACK.svg" alt=""/>
                                        </li>
                                    </Link>
                                ))}
                            </ul>
                        )}
                    </div>
                </div>
            )}
            {
                searchOpen && (
                    <div
                        className={styles.overlay}
                        onClick={() => {
                            setSearchOpen(false);
                            setSearchData(null);
                        }}
                    />
                )
            }
            {
                searchOpen && (
                    <div className={styles.search}>
                        <div className={styles.search_bar}>
                            <div className={styles.search_bar_wrapper}>
                                <img src="/images/header/greenLoupe.svg" alt=""/>
                                <input
                                    type="text"
                                    placeholder="Текстовый запрос"
                                    className={styles.search_input}
                                    onKeyDown={(e) => handleInputChange(e)} // функция для управления вводом
                                />
                            </div>
                            <div className={styles.search_bar_close}>
                                <img
                                    src="/images/header/closeBurger.svg"
                                    alt="" style={{cursor: 'pointer'}}
                                    onClick={() => {
                                        setSearchOpen(false);
                                        setSearchData(null);
                                    }}
                                />
                            </div>
                        </div>
                        {
                            searchData && searchData.results > 0 && (
                                <div className={styles.search_results}>
                                    <h1>
                                        результатов <span>{searchData.results}</span>
                                    </h1>
                                    <div className={styles.search_results_results}>
                                        {
                                            searchData.data.map((item) => (
                                                <div
                                                    onClick={() => {setSearchOpen(false); setSearchData(null)}}
                                                className={styles.result}
                                                    key={item.link}
                                                >
                                                    <Link to={`/${item.link}`}>
                                                        {item.title}
                                                    </Link>
                                                </div>
                                            ))
                                        }
                                    </div>
                                </div>
                            )
                        }
                    </div>
                )
            }

            <div className={styles.header_left}>
                <div className={`${styles.header_elem} ${styles.header_kazgik}`}
                     ref={headerKazgikRef}
                >
                    <img src="/images/header/kazgikLogoColor.png" alt=""/>
                </div>
                <div className={`${styles.header_elem} ${styles.header_tat}`}>
                    <img src="/images/header/tat2030color.png" alt=""/>
                </div>
            </div>
            <Link to={'/'}>
                <div className={`${styles.header_elem} ${styles.header_logo}`}>
                    <span className={styles.left_text}>Народы</span>
                    <img src="/images/catalog/catalogLogo.png" alt="" />
                    <span className={styles.right_text}>Поволжья</span>
                </div>
            </Link>
            <div className={styles.header_right}>
                <div className={`${styles.header_elem}`}>
                    <img
                        src="/images/header/loupe.svg" alt=""
                        style={{cursor: 'pointer'}}
                        onClick={() => setSearchOpen(true)}
                    />
                </div>
                <div className={`${styles.header_elem}`}>
                    {
                        menuOpen ? (
                            <img src="/images/header/closeBurger.svg" alt=""
                                 style={{cursor: 'pointer'}}
                                 onClick={() => setMenuOpen(false)}
                            />
                        ) : (
                            <img src="/images/header/burger.svg" alt=""
                                 style={{cursor: 'pointer'}}
                                 onClick={() => setMenuOpen(true)}
                            />
                        )
                    }
                </div>
                <Link to={'/'}>
                    <div className={`${styles.header_elem}`}>
                        <span className={styles.about}>о проекте</span>
                        <img className={styles.about_image} src="/images/header/about.svg" alt=""/>
                    </div>
                </Link>
            </div>
        </div>
    );
};

export default CatalogHeader;
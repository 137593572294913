import React from 'react';
import styles from './CatalogChoosePage.module.scss';
import {Link} from "react-router-dom";

const CatalogChoosePage = () => {
    return (
        <div className={styles.main}>
            <div className={`${styles.block} ${styles.left}`}>
                <h1>Культуры</h1>
                <Link to={'/nations-culture'}>
                    <button>Подробнее</button>
                </Link>
            </div>
            <div className={`${styles.block} ${styles.right}`}>
                <h1>Народы</h1>
                <Link to={'/nations'}>
                    <button>Подробнее</button>
                </Link>
            </div>
        </div>
    );
};

export default CatalogChoosePage;
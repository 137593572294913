import React, {useEffect, useState} from 'react';
import styles from './CatalogPage.module.scss';
import {Link, useParams} from "react-router-dom";
import axios from "axios";

const CatalogPage = () => {

    const params = useParams();

    const [isOpenNations, setIsOpenNations] = useState(false);
    const [isOpenPopular, setIsOpenPopular] = useState(false);
    const [currentCategory, setCurrentCategory] = useState(null);
    const [selectedCategory, setSelectedCategory] = useState(null);
    const [subCategories, setSubCategories] = useState([]);
    const [nations, setNations] = useState([]);
    const [currentNation, setCurrentNation] = useState()
    const [fetchedData, setFetchedData] = useState([]);
    const [filteredData, setFilteredData] = useState([]);

    const handleToggleNations = () => {
        setIsOpenNations((prevState) => !prevState); // Переключение состояния
    };

    const handleTogglePopular = () => {
        setIsOpenPopular((prevState) => !prevState);
    }

    useEffect(() => {
        const fetchContent = async () => {
            try {
                if (currentNation) {
                    const response = await axios.get(`https://back.xn--80aefjyiibbhi8kta6b.xn--p1ai/api/nations/${currentNation.id}/${params.categoryId}`);
                    console.log(response.data)
                    setFetchedData(response.data);
                }

            } catch (e) {
                console.error(e);
            }
        }
        fetchContent()
    }, [currentNation])

    useEffect(() => {
        const fetchCategories = async () => {
            try {
                const response = await axios.get(`https://back.xn--80aefjyiibbhi8kta6b.xn--p1ai/api/nations/categories/${params.categoryId}`);
                const nationsResponse = await axios.get('https://back.xn--80aefjyiibbhi8kta6b.xn--p1ai/api/nations')
                setCurrentCategory(response.data);
                console.log(response.data)
                setNations(nationsResponse.data);

            } catch (e) {
                console.error(e);
            }
        }
        fetchCategories();
    }, [])

    useEffect(() => {
        if (currentCategory) {
            setSubCategories(currentCategory.sub_category);
            setSelectedCategory(null); // Сброс выбранной подкатегории
        }
    }, [currentCategory]);

    useEffect(() => {
        if (selectedCategory) {
            const filtered = fetchedData.filter(
                (item) => item.sub_category === selectedCategory.title
            );
            setFilteredData(filtered);
        } else {
            setFilteredData(fetchedData); // Если нет выбранной подкатегории, показываем все
        }
    }, [selectedCategory, fetchedData]);

    useEffect(() => {
        if (nations.length > 0) {
            setCurrentNation(nations[0]);
        }
    }, [nations]);

    if (!currentCategory) {
        return <h3>Загрузка...</h3>;
    }

    return (
        <div className={styles.catalog}>
            <div className={styles.main}>
                <div className={styles.main_header}>
                    <div className='breadcrumbs'>
                        <Link to={'/catalog_choose'}>
                            <p>каталог</p>
                        </Link>
                        <img src="/images/catalog/rightBack.svg" alt=""/>
                        <Link to={'/nations-culture'}>
                            <p>культуры</p>
                        </Link>
                        <img src="/images/catalog/rightBack.svg" alt=""/>
                        {currentCategory && <p className='breadcrumbs_active' style={{color: '#1F887D'}}>{currentCategory?.title}</p>}
                    </div>
                    <div className={styles.firstRow}>
                        {currentCategory && <h1>{currentCategory?.title}</h1>}
                        <div className={styles.rightPart}>
                            <div className={`${styles.customSelect}`}>
                                <select
                                    onMouseDown={handleToggleNations}// Когда select получает фокус
                                    onBlur={() => setIsOpenNations(false)}
                                    onChange={(e) => {
                                        const selectedNationTitle = e.target.value; // Получаем выбранное значение
                                        const selectedNation = nations.find(
                                            (nation) => nation.title === selectedNationTitle
                                        );
                                        setCurrentNation(selectedNation);
                                    }}
                                >
                                    {nations.map(nation => (
                                        <option key={nation.id}>{nation.title}</option>
                                    ))}
                                </select>
                                <img
                                    src={
                                        isOpenNations
                                            ? "/images/catalog/upBack.svg" // Картинка при открытии
                                            : "/images/catalog/downBack.svg" // Картинка по умолчанию
                                    }
                                    alt="Arrow"
                                />
                            </div>
                            <div className={`${styles.customSelect} ${styles.selectRight}`}>
                                <select
                                    onMouseDown={handleTogglePopular}// Когда select получает фокус
                                    onBlur={() => setIsOpenPopular(false)}
                                >
                                    <option>По популярности</option>
                                </select>
                                <img
                                    src={
                                        isOpenPopular
                                            ? "/images/catalog/upBack.svg" // Картинка при открытии
                                            : "/images/catalog/downBack.svg" // Картинка по умолчанию
                                    }
                                    alt="Arrow"
                                />
                            </div>
                            <div className={styles.search}>
                                <img src="/images/header/loupe.svg" alt=""/>
                            </div>
                        </div>
                    </div>
                    <div className={styles.thirdRow}>
                        <div
                            key="all"
                            onClick={() => setSelectedCategory(null)}
                            className={`${!selectedCategory ? styles.selected : ''}`}
                        >
                            Все
                        </div>
                        {currentCategory?.sub_category.map((item) => (
                            <div
                                key={item.id}
                                onClick={() => setSelectedCategory(item)}
                                className={`${selectedCategory?.id === item.id ? styles.selected : ''}`}
                            >
                                {item.title}
                            </div>
                        ))}
                    </div>
                </div>
                <div className={styles.content}>
                    {filteredData.length > 0 ? (
                        filteredData.map((item) => (
                            <Link key={item.id} to={`/nation/${currentNation.id}/${params.categoryId}/${item.id}`}>
                                <div className={styles.content_item}>
                                    <div className={styles.content_item_image}>
                                        <img src={item.images.file} alt=""/>
                                    </div>
                                    <div className={styles.content_item_text}>
                                        {item.title}
                                    </div>
                                </div>
                            </Link>
                        ))
                    ) : (
                        <p>Нет данных для выбранной подкатегории</p>
                    )}
                </div>
            </div>
        </div>
    );
};

export default CatalogPage;
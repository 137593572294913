import React, {useEffect, useRef, useState} from 'react';
import {Link, useNavigate, useParams} from "react-router-dom";
import styles from './DescriptionPage.module.scss';
import axios from "axios";
import { Navigation } from 'swiper/modules'
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/swiper-bundle.css';

const DescriptionPage = () => {

    const params = useParams();
    const [audioData, setAudioData] = useState([]);
    const [description, setDescription] = useState(null);
    const [isPlaying, setIsPlaying] = useState([]);
    const [progress, setProgress] = useState([]);
    const navigate = useNavigate();
    const audioRefs = useRef([]);
    const [currentPlaying, setCurrentPlaying] = useState(null);
    const [volume, setVolume] = useState(new Array(audioData.length).fill(1)); // Громкость по умолчанию 100%
    const [similar, setSimilar] = useState([]);

    const handleVolumeChange = (index, value) => {
        const newVolume = value / 100; // Приведение значения к диапазону [0, 1]
        const updatedVolume = [...volume];
        updatedVolume[index] = newVolume;
        setVolume(updatedVolume);

        if (audioRefs.current[index]) {
            audioRefs.current[index].volume = newVolume;
        }
    };


    const handlePdfClick = (url) => {
        navigate(`/pdf-viewer`, { state: { pdfUrl: url } });
    };

    useEffect(() => {
        if (description) {
            const fetchSimilarMaterials = async () => {
                try {
                    const response = await axios.get(`https://back.xn--80aefjyiibbhi8kta6b.xn--p1ai/api/nations/${params.id}/${params.categoryId}`);

                    const filteredData = response.data.filter(
                        item => item.sub_category === description.sub_category
                    );

                    const shuffleArray = (array) => {
                        return array.sort(() => Math.random() - 0.5);
                    };

                    // Перемешиваем массив и берем первые 4 элемента
                    const randomItems = shuffleArray(filteredData).slice(0, 4);

                    setSimilar(randomItems);
                } catch (error) {
                    console.error('Error fetching similar materials:', error);
                }
            };

            fetchSimilarMaterials();
        }
    }, [params.id, params.categoryId, description])

    useEffect(() => {
        const fetchDescription = async () => {
            const response = await axios.get(`https://back.xn--80aefjyiibbhi8kta6b.xn--p1ai/api/nations/content/${params.descId}`);
            setDescription(response.data);
        };
        fetchDescription();
    }, [params.descId]);

    useEffect(() => {
        if (description?.audios?.length > 0) {
            const fetchDurations = async () => {
                const dataWithDurations = await Promise.all(
                    description.audios?.map((audioUrl) =>
                        new Promise((resolve) => {
                            const audio = new Audio(audioUrl.file);
                            audio.addEventListener("loadedmetadata", () => {
                                const duration = Math.floor(audio.duration);
                                const minutes = Math.floor(duration / 60);
                                const seconds = duration % 60;
                                resolve({
                                    url: audioUrl,
                                    duration: `${minutes}:${seconds.toString().padStart(2, "0")}`,
                                });
                            });
                        })
                    )
                );

                console.log(dataWithDurations);
                setAudioData(dataWithDurations);
                setIsPlaying(new Array(dataWithDurations.length).fill(false));
                setProgress(new Array(dataWithDurations.length).fill(0));
            };
            fetchDurations();
        }
    }, [description]);

    const toggleAudio = (index) => {
        if (currentPlaying === index) {
            if (audioRefs.current[index].paused) {
                audioRefs.current[index].play();
                setIsPlaying((prev) => prev.map((_, i) => i === index));
            } else {
                audioRefs.current[index].pause();
                setIsPlaying((prev) => prev.map((_, i) => false));
            }
        } else {
            if (currentPlaying !== null && audioRefs.current[currentPlaying]) {
                audioRefs.current[currentPlaying].pause();
                audioRefs.current[currentPlaying].currentTime = 0;
            }
            audioRefs.current[index].play();
            setCurrentPlaying(index);
            setIsPlaying((prev) => prev.map((_, i) => i === index));
        }
    };

    const playAudio = (index) => {
        if (currentPlaying !== null && audioRefs.current[currentPlaying]) {
            // Остановить текущий воспроизводимый трек
            audioRefs.current[currentPlaying].pause();
            audioRefs.current[currentPlaying].currentTime = 0;
            setIsPlaying((prev) =>
                prev.map((playing, i) => (i === currentPlaying ? false : playing))
            );
        }

        // Воспроизвести выбранный трек
        audioRefs.current[index].play();
        setCurrentPlaying(index);
        setIsPlaying((prev) => prev.map((_, i) => i === index));
    };


    const volumeSlider = document.querySelector('.audio-block_volume');

    volumeSlider?.addEventListener('input', (e) => {
        const volumeLevel = e.target.value; // Получаем значение ползунка (0-100)
        e.target.style.setProperty('--volume-level', `${volumeLevel}%`); // Устанавливаем переменную CSS
    });

    const handleProgressChange = (index, value) => {
        const progressPercent = (value / audioRefs.current[index].duration) * 100;
        document.documentElement.style.setProperty("--progress-percent", `${progressPercent}%`);
        const audio = audioRefs.current[index];
        if (audio) {
            audio.currentTime = value;
            setProgress((prev) => {
                const newProgress = [...prev];
                newProgress[index] = value;
                return newProgress;
            });
        }
    };

    const updateProgress = (index) => {
        const duration = audioRefs.current[index]?.duration || 1;
        const currentTime = audioRefs.current[index]?.currentTime || 0;
        const progressPercent = (currentTime / duration) * 100;

        // Обновляем переменную
        document.documentElement.style.setProperty("--progress-percent", `${progressPercent}%`);

        setProgress((prev) =>
            prev.map((value, i) => (i === index ? currentTime : value))
        );
    };

    if (!description) {
        return <div>Загрузка...</div>;
    }

    return (
        <div className={styles.descriptionPage}>
            <div className={styles.header}>
                <div className='breadcrumbs'>
                    <Link to={`/catalog_choose`}>
                        <p>каталог</p>
                    </Link>
                    <img src="/images/catalog/rightBack.svg" alt=""/>
                    <Link to={`/nations`}>
                        <p>народы</p>
                    </Link>
                    <img src="/images/catalog/rightBack.svg" alt=""/>
                    <Link to={`/nation/${params.id}`}>
                        <p>{description.nation}</p>
                    </Link>
                    <img src="/images/catalog/rightBack.svg" alt=""/>
                    <Link to={`/nation/${params.id}/${params.categoryId}`}>
                        <p>{description.category.title}</p>
                    </Link>
                    <img src="/images/catalog/rightBack.svg" alt=""/>
                    <Link to={`/nation/${params.id}/${params.categoryId}`}>
                        <p>{description.sub_category}</p>
                    </Link>
                    <img src="/images/catalog/rightBack.svg" alt=""/>
                    <p className='breadcrumbs_active'>{description.title}</p>
                </div>
            </div>
            <div className={styles.main}>
                <h1>{description.title}</h1>
                <div className={styles.text}
                     dangerouslySetInnerHTML={{ __html: description.description }}
                >
                </div>
                <div className={styles.video}>
                    {
                        description.videos?.length > 0 && (
                            description.videos?.map((video) => (
                                <div className={styles.videoBlock} key={video}>
                                    <video controls src={video.file} poster={video.video_preview}></video>
                                </div>
                            ))
                        )
                    }
                </div>
                {
                    description.books.length > 0 && (
                        description.books.map((book) => (
                            <div className={styles.pdf}>
                                <h2>
                                    {book.book_title}
                                </h2>
                                <div className={styles.pdf_description}>
                                    <div className={styles.book_image}>
                                        <img src={book.book_image} alt=""/>
                                    </div>
                                    <div className={styles.pdf_description_text}>
                                        <p>{book.annotation}</p>
                                        <div className={styles.meta}>
                                            {book.authors && <div className={styles.pdf_description_text_meta}><p>Автор</p><p>{book.authors}</p></div>}
                                            {book.publication_year && <div className={styles.pdf_description_text_meta}><p>Дата публикации</p><p>{book.publication_year}</p></div>}
                                        </div>
                                    </div>
                                </div>
                                <div
                                    className={styles.pdfBlock}
                                    onClick={() => handlePdfClick(book.file)}
                                >
                                    <div className={styles.image}>
                                        <img src="/images/pdfPic.png" alt=""/>
                                    </div>
                                    <p>
                                        {book.book_title}
                                    </p>
                                    <img src="/images/ARROW_RIGHT.svg" alt="" className={styles.arrow}/>
                                </div>
                            </div>
                        ))
                    )
                }
                <div className={styles.audio}>
                    <h2>{description.audios.audio_title}</h2>
                    {audioData.map((audio, index) => (
                        <div className={styles.audioAndDesc} key={index}>
                            <div
                                className={`audio-block ${isPlaying[index] ? "active" : ""}`}
                                onClick={() => playAudio(index)}
                            >
                                <div className={`audio-block_firstDeck`}>
                                    <div className="audio-block_icon">
                                        <img src="/images/songIcon.svg" alt="" />
                                    </div>
                                    <div className="audio-info">
                                        <span className="audio-title">{audio.url.audio_title}</span>
                                    </div>
                                    {
                                        currentPlaying !== index && (
                                            <div className="audio-duration">{audio.duration}</div>
                                        )
                                    }
                                    <audio
                                        ref={(el) => (audioRefs.current[index] = el)}
                                        src={audio.url.file}
                                        onTimeUpdate={() => updateProgress(index)}
                                        onEnded={() => {
                                            setCurrentPlaying(null);
                                            setIsPlaying((prev) => prev.map((_, i) => false));
                                        }}
                                    ></audio>
                                    {
                                        currentPlaying === index && (
                                            <div className="volume-control">
                                                <img src="/images/volumeIcon.svg" alt=""/>
                                                <input
                                                    id={`volume-${index}`}
                                                    className="audio-block_volume"
                                                    type="range"
                                                    min="0"
                                                    max="100"
                                                    value={volume[index] * 100}
                                                    onChange={(e) => handleVolumeChange(index, e.target.value)}
                                                    onClick={(e) => e.stopPropagation()}
                                                />
                                            </div>
                                        )
                                    }
                                </div>
                                {
                                    currentPlaying === index && (
                                        <div className={`audio-block_secondDeck`}>
                                            <img
                                                onClick={(e) => {
                                                    e.stopPropagation();
                                                    toggleAudio(index)
                                                }}
                                                src={isPlaying[index] ? "/images/songStop.svg" : '/images/songPlay.svg'} alt=""
                                                className={`controlIcon`}
                                            />
                                            <input
                                                className={`audio_block_Range`}
                                                type="range"
                                                min="0"
                                                max={audioRefs.current[index]?.duration || 0}
                                                value={progress[index]}
                                                onChange={(e) => handleProgressChange(index, e.target.value)}
                                                onClick={(e) => e.stopPropagation()}
                                            />
                                            <div className={`duration`}>{audio.duration}</div>
                                        </div>
                                    )
                                }
                            </div>
                            <p>{audio.url.audio_description}</p>
                        </div>
                    ))}
                </div>
            </div>
            {
                description.images?.additional_images?.length > 0 && (
                    <div className={styles.gallery}>
                        <Swiper
                            modules={[Navigation]}
                            slidesPerView={3}
                            centeredSlides={true}
                            loop={true}
                            initialSlide={1}
                            spaceBetween={160}
                            pagination={{ clickable: true }}
                            navigation={{
                                prevEl: '.button--prev',
                                nextEl: '.button--next'
                            }}
                        >
                            {
                                description.images.additional_images.map((image, index) => (
                                    <SwiperSlide key={index}>
                                        <div className={styles.slider_picture}>
                                            <img src={image.file} alt="slider image" />
                                        </div>
                                        <p>{image.description}</p>
                                    </SwiperSlide>
                                ))
                            }
                            <button className="button button--prev">
                                <img src="/images/prev.svg" alt=""/>
                            </button>
                            <button className="button button--next">
                                <img src="/images/next.svg" alt=""/>
                            </button>
                        </Swiper>
                    </div>
                )
            }
            <div className={styles.similarBlock}>
                <h2>
                    Похожие материалы
                </h2>
                <div className={styles.similarBlock_flex}>
                    {
                        similar.map((item) => (
                            <Link key={item.id} to={`/nation/${params.id}/${params.categoryId}/${item.id}`}>
                                <div className={styles.block}>
                                    <div className={styles.block_image}>
                                        <img src={item.images.file} alt=""/>
                                    </div>
                                    <p>
                                        {item.title}
                                    </p>
                                </div>
                            </Link>
                        ))
                    }
                </div>
            </div>
        </div>
    );
};

export default DescriptionPage;
// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.MainHeader_header__ex\\+kB {
  width: 100%;
  background-color: var(--mainPage-color);
  padding: 20px 130px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

@media screen and (max-width: 1440px) {
  .MainHeader_header__ex\\+kB {
    padding: 20px 34px;
  }
}`, "",{"version":3,"sources":["webpack://./src/components/headers/MainHeader/MainHeader.module.scss"],"names":[],"mappings":"AAAA;EACE,WAAA;EACA,uCAAA;EACA,mBAAA;EACA,aAAA;EACA,8BAAA;EACA,mBAAA;AACF;;AAEA;EACE;IACE,kBAAA;EACF;AACF","sourcesContent":[".header {\n  width: 100%;\n  background-color: var(--mainPage-color);\n  padding: 20px 130px;\n  display: flex;\n  justify-content: space-between;\n  align-items: center;\n}\n\n@media screen and (max-width: 1440px) {\n  .header {\n    padding: 20px 34px;\n  }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"header": `MainHeader_header__ex+kB`
};
export default ___CSS_LOADER_EXPORT___;

// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("../../../public/images/catalog/Frame 1000002600.jpg", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_1___ = new URL("../../../public/images/catalog/Frame 1000002601.png", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
var ___CSS_LOADER_URL_REPLACEMENT_1___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_1___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `@charset "UTF-8";
.CatalogChoosePage_main__ErRF5 {
  display: grid;
  grid-template-columns: 1fr 1fr;
  height: calc(100vh - 90px);
}

.CatalogChoosePage_block__\\+UZFz {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  row-gap: 62px;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover; /* Заполнение блока */
  object-fit: scale-down;
}
.CatalogChoosePage_block__\\+UZFz h1 {
  color: white;
  font-size: 64px;
  font-weight: 400;
}
.CatalogChoosePage_block__\\+UZFz button {
  font-size: 16px;
  text-transform: uppercase;
  padding: 24px 32px;
  background-color: #FFFFFF;
  border-radius: 12px;
  border: none;
  font-weight: 600;
  cursor: pointer;
  color: #878787;
}

.CatalogChoosePage_left__Ov\\+sH {
  background-image: url(${___CSS_LOADER_URL_REPLACEMENT_0___});
}

.CatalogChoosePage_right__z2o02 {
  background-image: url(${___CSS_LOADER_URL_REPLACEMENT_1___});
}

@media screen and (max-width: 1024px) {
  .CatalogChoosePage_main__ErRF5 {
    height: auto;
    grid-template-columns: 1fr;
  }
  .CatalogChoosePage_block__\\+UZFz {
    height: 70vh;
  }
}`, "",{"version":3,"sources":["webpack://./src/pages/CatalogChoosePage/CatalogChoosePage.module.scss"],"names":[],"mappings":"AAAA,gBAAgB;AAAhB;EACE,aAAA;EACA,8BAAA;EACA,0BAAA;AAEF;;AACA;EACE,aAAA;EACA,sBAAA;EACA,uBAAA;EACA,mBAAA;EACA,aAAA;EACA,2BAAA;EACA,4BAAA;EACA,sBAAA,EAAA,qBAAA;EACA,sBAAA;AAEF;AADE;EACE,YAAA;EACA,eAAA;EAEA,gBAAA;AAEJ;AAAE;EACE,eAAA;EACA,yBAAA;EACA,kBAAA;EACA,yBAAA;EACA,mBAAA;EACA,YAAA;EACA,gBAAA;EACA,eAAA;EACA,cAAA;AAEJ;;AAEA;EACE,yDAAA;AACF;;AAEA;EACE,yDAAA;AACF;;AAEA;EACE;IACE,YAAA;IACA,0BAAA;EACF;EAEA;IAEE,YAAA;EADF;AACF","sourcesContent":[".main {\n  display: grid;\n  grid-template-columns: 1fr 1fr;\n  height: calc(100vh - 90px);\n}\n\n.block {\n  display: flex;\n  flex-direction: column;\n  justify-content: center;\n  align-items: center;\n  row-gap: 62px;\n  background-position: center;\n  background-repeat: no-repeat;\n  background-size: cover; /* Заполнение блока */\n  object-fit: scale-down;\n  h1 {\n    color: white;\n    font-size: 64px;\n    //font-style: italic;\n    font-weight: 400;\n  }\n  button {\n    font-size: 16px;\n    text-transform: uppercase;\n    padding: 24px 32px;\n    background-color: #FFFFFF;\n    border-radius: 12px;\n    border: none;\n    font-weight: 600;\n    cursor: pointer;\n    color: #878787;\n  }\n}\n\n.left {\n  background-image: url(\"../../../public/images/catalog/Frame 1000002600.jpg\");\n}\n\n.right {\n  background-image: url(\"../../../public/images/catalog/Frame 1000002601.png\");\n}\n\n@media screen and (max-width: 1024px) {\n  .main {\n    height: auto;\n    grid-template-columns: 1fr;\n  }\n\n  .block {\n    //width: 100vw;\n    height: 70vh;\n  }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"main": `CatalogChoosePage_main__ErRF5`,
	"block": `CatalogChoosePage_block__+UZFz`,
	"left": `CatalogChoosePage_left__Ov+sH`,
	"right": `CatalogChoosePage_right__z2o02`
};
export default ___CSS_LOADER_EXPORT___;

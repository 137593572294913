const CustomIcon = ({ color = "#47A4AD", className = "" }) => (
    <svg
        className={className}
        width="46"
        height="46"
        viewBox="0 0 46 46"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path
            d="M13.418 13.417H32.5846V32.5837"
            stroke={color}
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
        <path
            d="M13.418 32.5837L32.5846 13.417"
            stroke={color}
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
    </svg>
);

export default CustomIcon;
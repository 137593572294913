import React from 'react';
import styles from './CatalogLayout.module.scss';
import Footer from "../../Footer/Footer";
import CatalogHeader from "../../headers/CatalogHeader/CatalogHeader";
import CatalogFooter from "../../Footer/Footer";

const CatalogLayout = ({children}) => {
    return (
        <>
            <CatalogHeader></CatalogHeader>
            <main>{children}</main>
            <CatalogFooter></CatalogFooter>
        </>
    );
};

export default CatalogLayout;
import React from 'react';
import styles from './MainFooter.module.scss';

const MainFooter = () => {
    return (
        <>
            <div className={styles.deckFour}>
                <div className={styles.text}>
                    <img src="/images/footer/mainFooter.png" alt=""/>
                </div>
            </div>
            <div className={styles.footer}>
                <div className={styles.footer_firstDeck}>
                    <div className={styles.leftSide}>
                        <span>
                            info@narodypovolzhya.ru
                        </span>
                        <span>
                            Россия, г. Казань, ул. оренбургский тракт, д. 3
                        </span>
                        <div className={styles.button}>
                            обратная связь
                        </div>
                    </div>
                    <div className={styles.rightSide}>
                        <div className={styles.block}>
                            <img src="/images/footer/vk.svg" alt=""/>
                        </div>
                        <div className={styles.block}>
                            <img src="/images/footer/tg.svg" alt=""/>
                        </div>
                        <div className={styles.block}>
                            <img src="/images/footer/yt.svg" alt=""/>
                        </div>
                    </div>
                </div>
                <div className={styles.footer_bottom}>
                    <span className={styles.footer_bottom_copyright}>
                        © 2024 Проект "Народы Поволжья". Все права защищены.
                    </span>
                    <span>
                        Политика конфиденциальности
                    </span>
                </div>
            </div>
        </>
    );
};

export default MainFooter;
import React, {useEffect, useState} from 'react';
import {Link, useParams} from "react-router-dom";
import styles from './SingleNationPage.module.scss';
import axios from "axios";
import CustomIcon from "../../components/CustomArrowIcon/CustomArrowIcon";

const SingleNationPage = () => {

    const params = useParams()

    const [currentNation, setCurrentNation] = useState(null);
    const [hoveredCategory, setHoveredCategory] = useState(null);

    useEffect(() => {
        const fetchNation = async () => {
            try {
                const response = await axios.get(`https://back.xn--80aefjyiibbhi8kta6b.xn--p1ai/api/nations/${params.id}`);
                console.log(response.data)
                setCurrentNation(response.data);
            } catch (e) {
                console.error('Ошибка при получении сказок', e)
            }
        }
        fetchNation();
    }, [params.id])

    useEffect(() => {
        console.log(currentNation)
    }, [currentNation])

    if (!currentNation) {
        return <div>Загрузка...</div>;
    }

    return (
        <>
            <div className={styles.header}>
                <div className={styles.header_frame}>
                    <div className='breadcrumbs'>
                        <Link to={`/catalog_choose`}>
                            <p>каталог</p>
                        </Link>
                        <img src="/images/catalog/rightBack.svg" alt=""/>
                        <Link to={`/nations`}>
                            <p>народы</p>
                        </Link>
                        <img src="/images/catalog/rightBack.svg" alt=""/>
                        {currentNation && <p style={{color: currentNation.nation_color}} className='breadcrumbs_active'>{currentNation?.nation_name}</p>}
                    </div>
                </div>
                <div className={`${styles.header_frame} ${styles.header_frame_Center}`}>
                    <h1>
                        {currentNation?.nation_name}
                    </h1>
                </div>
                <div className={styles.header_frame}>

                </div>
            </div>
            <div className={styles.wrapper}
                 style={{
                     backgroundColor: currentNation.nation_color,
                     backgroundImage: `url(${currentNation.nation_big_pattern})`
                 }}
            >
                <div className={styles.abstract}>
                    <p>
                        {currentNation?.nation_description}
                    </p>
                </div>
                <div className={styles.main}>
                    {
                        currentNation.categories.map((category) => (
                            <Link to={`${category.id}`} key={category.id}>
                                <div
                                    className={styles.block}
                                    onMouseEnter={() => setHoveredCategory(category.id)}
                                    onMouseLeave={() => setHoveredCategory(null)}
                                    style={{
                                        backgroundColor:
                                            hoveredCategory === category.id ? currentNation.nation_color : 'white',
                                        transition: 'background-color 0.3s ease',
                                        // border: `1.5px solid ${currentNation.nation_color}`,
                                        color: 'white'
                                    }}
                                >
                                    <div
                                        className={styles.title}
                                        style={{
                                            color:
                                                hoveredCategory === category.id ? 'white' : currentNation.nation_color
                                        }}
                                    >
                                        {category.title}
                                    </div>
                                    {
                                        hoveredCategory === category.id ? (
                                            <img src={category.category_image} alt="" className={styles.pattern}/>
                                        ) : <img src={category.category_color_image} alt="" className={styles.pattern}/>
                                    }
                                    {
                                        hoveredCategory === category.id ? (
                                            <img src="/images/catalog/LINK_ARROW.svg" alt="" className={styles.arrow}/>
                                        ) : (
                                            <CustomIcon color={currentNation.nation_color} className={styles.link}/>
                                        )
                                    }
                                </div>
                            </Link>
                        ))
                    }
                </div>
            </div>
        </>
    );
};

export default SingleNationPage;
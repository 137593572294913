import './App.css';
import {BrowserRouter, Route, Routes} from "react-router-dom";
import CatalogPage from "./pages/CatalogPage/CatalogPage";
import MainPage from "./pages/MainPage/MainPage";
import MainLayout from "./components/layouts/MainLayout/MainLayout";
import CatalogLayout from "./components/layouts/CatalogLayout/CatalogLayout";
import CatalogChoosePage from "./pages/CatalogChoosePage/CatalogChoosePage";
import NationsPage from "./pages/NationsPage/NationsPage";
import SingleNationPage from "./pages/SingleNationPage/SingleNationPage";
import CategoryPage from "./pages/CategoryPage/CategoryPage";
import DescriptionPage from "./pages/DescriptionPage/DescriptionPage";
import NationsCulture from "./pages/NationsCulture/NationsCulture";
import PdfViewerPage from "./pages/PdfViewerPage/PdfViewerPage";
import ScrollToTop from "./components/ScrollToTop";

function App() {
  return (
    <BrowserRouter>
        <ScrollToTop />
      <Routes>
          <Route
              path='/'
              element={
              <MainLayout>
                <MainPage/>
              </MainLayout>
          }/>
          <Route
              path='/catalog_choose'
              element={
              <CatalogLayout>
                  <CatalogChoosePage/>
              </CatalogLayout>
              }
          />
          <Route
              path='/nations'
              element={
                  <CatalogLayout>
                      <NationsPage/>
                  </CatalogLayout>
              }
          />
          <Route
              path='/nations-culture'
              element={
                  <CatalogLayout>
                      <NationsCulture/>
                  </CatalogLayout>
              }
          />
          <Route
              path='/nation/:id'
              element={
                  <CatalogLayout>
                      <SingleNationPage/>
                  </CatalogLayout>
              }
          />
          <Route
              path='/nation/:nationId/:categoryId'
              element={
                  <CatalogLayout>
                      <CategoryPage/>
                  </CatalogLayout>
              }
          />
          <Route
              path='/nation/:id/:categoryId/:descId'
              element={
                  <CatalogLayout>
                      <DescriptionPage/>
                  </CatalogLayout>
              }
          />
          <Route
              path='/nations-culture/catalog/:categoryId'
              element={
                  <CatalogLayout>
                      <CatalogPage/>
                  </CatalogLayout>
            }
          />
          <Route
            path='/pdf-viewer'
            element={
                <PdfViewerPage />
            }
          >
          </Route>
      </Routes>
    </BrowserRouter>
  );
}

export default App;

// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.NationsCulture_main__RLQs7 {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
  height: calc(100vh - 180px);
}

@media screen and (max-width: 1200px) {
  .NationsCulture_main__RLQs7 {
    height: 200vh;
    grid-template-columns: repeat(2, 1fr);
  }
}`, "",{"version":3,"sources":["webpack://./src/pages/NationsCulture/NationsCulture.module.scss"],"names":[],"mappings":"AAAA;EACE,aAAA;EACA,0CAAA;EACA,2BAAA;AACF;;AAEA;EACE;IACE,aAAA;IACA,qCAAA;EACF;AACF","sourcesContent":[".main {\n  display: grid;\n  grid-template-columns: 1fr 1fr 1fr 1fr 1fr;\n  height: calc(100vh - 180px);\n}\n\n@media screen and (max-width: 1200px) {\n  .main {\n    height: 200vh;\n    grid-template-columns: repeat(2, 1fr);\n  }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"main": `NationsCulture_main__RLQs7`
};
export default ___CSS_LOADER_EXPORT___;

import React, {useRef, useState} from 'react';
import styles from './MainPage.module.scss';
import {Link} from "react-router-dom";
import { Navigation } from 'swiper/modules'
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/swiper-bundle.css';


const slides = [
    { img: "images/main/bash.jpg", title: "Башкиры", description: "Описание для Башкир." },
    { img: "images/main/tat.jpg", title: "Татары", description: "Описание для Татар." },
    { img: "images/main/russians.png", title: "Русские", description: "Описание для Русских." },
    { img: "images/main/udmurt.jpg", title: "Удмурты", description: "Описание для Удмуртов." },
    { img: "images/main/mari.jpg", title: "Марийцы", description: "Описание для Марийцев." },
    { img: "images/main/chuvash.jpg", title: "Чуваши", description: "Описание для Чувашей." },
    { img: "images/main/mordv.jpg", title: "Мордва", description: "Описание для Чувашей." },
];

const MainPage = () => {

    const swiperRef = useRef(null);

    const handleSlideChange = () => {
        const swiperInstance = swiperRef.current;
        if (swiperInstance && swiperInstance.slides) {
            // Удаляем кастомный класс со всех слайдов
            swiperInstance.slides.forEach((slide) => {
                slide.classList.remove(styles.activeSlide);
            });

            // Добавляем кастомный класс активному слайду
            const activeSlide = swiperInstance.slides[swiperInstance.activeIndex];
            activeSlide.classList.add(styles.activeSlide);
        }
    };


    return (
        <>
            <div className={styles.deckOne}>
                <img src="images/main/flowerLeft.png" alt="" className={styles.flowerLeft}/>
                <img src="images/main/flowerRight.png" alt="" className={styles.flowerRight}/>
                <h1>Откройте мир традиций и обычаев народов <span>самобытного региона</span></h1>
                <img src="images/main/logotype.png" alt="" className={styles.logotype}/>
                <p>Добро пожаловать в мир народов Поволжья — регион, где переплетаются истории, традиции и творчество. Исследуйте уникальное культурное наследие, узнавайте о самобытности каждого народа и открывайте для себя богатство многообразия.</p>
                <Link to={'/catalog_choose'}>
                    <button>Познакомиться с народами</button>
                </Link>
            </div>
            <div className={styles.deckTwo}>
                <div className={styles.text}>
                    <div>
                        <h2>Культурный путеводитель по <span>сердцу</span> Поволжья</h2>
                        <p>
                            Проект "Народы Поволжья" посвящен сохранению и популяризации уникального культурного наследия народов региона. Мы стремимся показать разнообразие традиций, объединяющих историю и современность Поволжья.
                        </p>
                    </div>
                    <button>Подробнее</button>
                </div>
                <img src="images/main/mainEllipse.png" alt="" className={styles.mainEllipse}/>
            </div>
            <div className={styles.deckThree}>
                <img src="/images/main/deckThreeLeftPattern.png" alt="" className={styles.leftPattern}/>
                <img src="/images/main/deckThreeRightPattern.png" alt="" className={styles.rightPattern}/>
                <h2>Вся культура народов<br/> Поволжья в одном месте</h2>
                <div className={styles.slider}>
                    <Swiper
                        modules={[Navigation]}
                        breakpoints={{
                            1920: {
                                slidesPerView: 3,
                                spaceBetween: 45.5
                            },
                            1600: {
                                slidesPerView: 3,
                                spaceBetween: 1
                            },
                            1300: {
                                slidesPerView: 2,
                                spaceBetween: 20
                            },
                            1200: {
                                slidesPerView: 2,
                                spaceBetween: 20
                            },
                            768: {
                                slidesPerView: 1
                            },
                        }}
                        centeredSlides={true}
                        loop={true}
                        initialSlide={2}
                        spaceBetween={45.5}
                        onSlideChange={handleSlideChange}
                        onSwiper={(swiper) => (swiperRef.current = swiper)}
                        pagination={{ clickable: true }}
                        navigation={{
                            prevEl: `.${styles.sliderPrev}`,
                            nextEl: `.${styles.sliderNext}`,
                        }}
                        slideClass={styles.swiperSlide}
                    >
                        {
                            slides.map((item, index) => (
                                <SwiperSlide key={index} className={styles.swiperSlide}>
                                    <div className={styles.slider_picture}>
                                        <img src={item.img} alt="slider image" />
                                    </div>
                                    <h2>{item.title}</h2>
                                </SwiperSlide>
                            ))
                        }
                        <button className={styles.sliderPrev}>
                            <img src="/images/prev.svg" alt=""/>
                        </button>
                        <button className={styles.sliderNext}>
                            <img src="/images/next.svg" alt=""/>
                        </button>
                    </Swiper>
                </div>
            </div>
            <div className={styles.deckFour}>
                <h2>Собрание <span>удивительных</span><br/>подборок</h2>
                <div className={styles.deckFour_main}>
                    <div className={styles.deckFour_main_firstRow}>
                        <div className={styles.bigPicture}>
                            <h2>
                                Уникальные традиции и обряды Мордовии
                            </h2>
                            <img src="images/main/Frame 1000002708.jpg" alt=""/>
                        </div>
                        <div className={styles.smallPicture}>
                            <div>
                                <h2>
                                    Сборник лучших сказок и песен народов Поволжья
                                </h2>
                                <img src="images/main/Frame 1000002713.jpg" alt=""/>
                            </div>
                            <div>
                                <h2>
                                    Топ-5 забытых праздников, которые возвращаются к жизни
                                </h2>
                                <img src="images/main/Frame 1000002714.jpg" alt=""/>
                            </div>
                        </div>
                    </div>
                    <div className={styles.deckFour_main_secondRow}>
                        <div className={styles.smallPicture}>
                            <div>
                                <h2>
                                    Сборник лучших сказок и песен народов Поволжья
                                </h2>
                                <img src="images/main/Frame 1000002713.jpg" alt=""/>
                            </div>
                            <div>
                                <h2>
                                    Топ-5 забытых праздников, которые возвращаются к жизни
                                </h2>
                                <img src="images/main/Frame 1000002714.jpg" alt=""/>
                            </div>
                        </div>
                        <div className={styles.bigPicture}>
                            <h2>
                                Уникальные традиции и обряды Мордовии
                            </h2>
                            <img src="images/main/Frame 1000002708.jpg" alt=""/>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default MainPage;
import React, {useEffect, useState} from 'react';
import {Link, useParams} from "react-router-dom";
import styles from './CategoryPage.module.scss';
import axios from "axios";

const CategoryPage = () => {

    const params = useParams();

    const [currentNation, setCurrentNation] = useState(null);
    const [category, setCategory] = useState(null);
    const [categoryList, setCategoryList] = useState(null);
    const [isOpenPopular, setIsOpenPopular] = useState(false);
    const [selectedCategory, setSelectedCategory] = useState(null);
    const [filteredData, setFilteredData] = useState([]);

    const handleTogglePopular = () => {
        setIsOpenPopular((prevState) => !prevState);
    }

    useEffect(() => {
        const fetchNation = async () => {
            try {
                const response = await axios.get(`https://back.xn--80aefjyiibbhi8kta6b.xn--p1ai/api/nations/${params.nationId}`);
                console.log(response.data)
                setCurrentNation(response.data);
            } catch (e) {
                console.error('Ошибка при получении сказок', e)
            }
        }
        fetchNation();
    },[params.nationId])

    useEffect(() => {
        const fetchCategoriesList = async () => {
            const response = await axios.get(`https://back.xn--80aefjyiibbhi8kta6b.xn--p1ai/api/nations/categories/${params.categoryId}`);
            console.log(response.data)
            setCategoryList(response.data)
        }
        fetchCategoriesList();
    }, [params.categoryId])

    useEffect(() => {
        const fetchCategory = async () => {
            try {
                const response = await axios.get(`https://back.xn--80aefjyiibbhi8kta6b.xn--p1ai/api/nations/${params.nationId}/${params.categoryId}`);
                console.log(response.data)
                setCategory(response.data);
            } catch (e) {
                console.error('Ошибка при получении сказок', e)
            }
        }
        fetchCategory();
    }, [params.categoryId])


    useEffect(() => {
        if (selectedCategory && category) {
            const filtered = category.filter(
                (item) => item.sub_category === selectedCategory.title
            );

            setFilteredData(filtered);
        } else {
            setFilteredData(category)
        }
    }, [selectedCategory, category]);

    if (!category || !categoryList || !filteredData) {
        return <div>Загрузка...</div>;
    }

    return (
        <div className={styles.categoryPage}>
            <div className={styles.header}>
                <div className='breadcrumbs'>
                    <Link to={`/catalog_choose`}>
                        <p>каталог</p>
                    </Link>
                    <img src="/images/catalog/rightBack.svg" alt=""/>
                    <Link to={`/nations`}>
                        <p>народы</p>
                    </Link>
                    <img src="/images/catalog/rightBack.svg" alt=""/>
                    <Link to={`/nation/${params.nationId}`}>
                        <p>{category[0].nation}</p>
                    </Link>
                    <img src="/images/catalog/rightBack.svg" alt=""/>
                    <p
                        style={{color: currentNation?.nation_color}}
                        className='breadcrumbs_active'>{category[0].category.title}</p>
                </div>
                <div className={styles.header_secondDeck}>
                    <div className={styles.header_secondDeck_headerFlex}>
                        <h1>{category[0].category.title}</h1>
                        <span style={{color: currentNation?.nation_color}}>({filteredData.length})</span>
                    </div>
                    <div className={styles.rightPart}>
                        <div className={`${styles.customSelect} ${styles.selectRight}`}>
                            <select
                                onMouseDown={handleTogglePopular}// Когда select получает фокус
                                onBlur={() => setIsOpenPopular(false)}
                            >
                                <option>По популярности</option>
                            </select>
                            <img
                                src={
                                    isOpenPopular
                                        ? "/images/catalog/upBack.svg" // Картинка при открытии
                                        : "/images/catalog/downBack.svg" // Картинка по умолчанию
                                }
                                alt="Arrow"
                            />
                        </div>
                        <div className={styles.search}>
                            <img src="/images/header/loupe.svg" alt=""/>
                        </div>
                    </div>
                </div>
            </div>
            <div className={styles.main}>
                <ul>
                    <li
                        key="all"
                        onClick={() => setSelectedCategory(null)}
                        className={`${!selectedCategory ? styles.active : ''}`}
                        style={{
                            backgroundColor: !selectedCategory ? currentNation?.nation_color : '#F6F6F6',
                            color: !selectedCategory ? 'white' : '#373737'
                        }}
                    >
                        Все
                    </li>
                    {
                        categoryList.sub_category.map((item) => (
                            <li
                                key={item.id}
                                onClick={() => setSelectedCategory(item)}
                                className={`${selectedCategory?.id === item.id ? styles.active : ''}`}
                                style={{
                                    backgroundColor: selectedCategory?.id === item.id ? currentNation?.nation_color : '#F6F6F6',
                                    color: selectedCategory?.id === item.id ? 'white' : '#373737'
                                }}
                            >
                                {item.title}
                            </li>
                        ))
                    }
                </ul>
                <div className={styles.main_grid}>
                    {
                        filteredData.length > 0 && (
                            filteredData.map((item) => (
                                <Link to={`${item.id}`} key={item.id}>
                                    <div className={styles.main_elem}>
                                        <div className={styles.main_elem_image}>
                                            <img src={item.images.file} alt=""/>
                                        </div>
                                        <div className={styles.main_elem_text}>
                                            {item.title}
                                        </div>
                                    </div>
                                </Link>
                            ))
                        )
                    }
                </div>
            </div>
        </div>
    );
};

export default CategoryPage;
import React, {useEffect, useState} from 'react';
import styles from "../NationsPage/NationsPage.module.scss";
import stylesTwo from './NationsCulture.module.scss';
import {Link} from "react-router-dom";
import axios from "axios";

const NationsCulture = () => {

    const [categories, setCategories] = useState([]);

    useEffect(() => {
        const fetchCategories = async () => {
            try {
                const response = await axios.get('https://back.xn--80aefjyiibbhi8kta6b.xn--p1ai/api/nations/categories');

                setCategories(response.data);

                console.log(response.data)

            } catch (e) {
                console.error(e);
            }
        }

        fetchCategories();
    }, [])

    return (
        <div className={styles.nationsPage}>
            <div className={styles.header}>
                <Link to='/catalog_choose'>
                    <div className={styles.header_elem}>
                        <img src="images/catalog/leftBack.svg" alt=""/>
                        <p>Каталог</p>
                    </div>
                </Link>
                <div className={styles.header_center}>
                    <h1>Культура народов</h1>
                </div>
                <div className={styles.header_elem}>

                </div>
            </div>
            <div className={stylesTwo.main}>
                {
                    categories.map((category) => (
                        <Link to={`catalog/${category.id}`} key={category.id}>
                            <div className={styles.block} style={{backgroundColor: category.color}}>
                                <div className={styles.title}>
                                    {category.title}
                                </div>
                                <img src={category.pattern} alt="" className={styles.pattern}/>
                                <img src="/images/catalog/LINK_ARROW.svg" alt="" className={styles.arrow}/>
                            </div>
                        </Link>
                    ))
                }
            </div>
        </div>
    );
};

export default NationsCulture;
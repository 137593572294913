import React from 'react';
import styles from './MainHeader.module.scss';

const MainHeader = () => {
    return (
        <div className={styles.header}>
            <img src="/images/header/kazgikLogo.png" alt=""/>
            <img src="/images/header/tat2030.png" alt=""/>
        </div>
    );
};

export default MainHeader;
import React, {useEffect, useState} from 'react';
import styles from './NationsPage.module.scss';
import axios from "axios";
import {Link} from "react-router-dom";

const NationsPage = () => {

    const [nations, setNations] = useState([]);

    useEffect(() => {
        const fetchNations = async () => {
            try {
                const response = await axios.get('https://back.xn--80aefjyiibbhi8kta6b.xn--p1ai/api/nations');
                console.log(response.data)
                setNations(response.data);
            } catch (e) {
                console.error('Ошибка при получении сказок', e)
            }
        }
        fetchNations();
    }, [])

    return (
        <div className={styles.nationsPage}>
            <div className={styles.header}>
                <Link to='/catalog_choose'>
                    <div className={styles.header_elem}>
                        <img src="images/catalog/leftBack.svg" alt=""/>
                        <p>Каталог</p>
                    </div>
                </Link>
                <div className={styles.header_center}>
                    <h1>Народы</h1>
                </div>
                <div className={styles.header_elem}>

                </div>
            </div>
            <div className={styles.main}>
                {
                    nations.map((nation) => (
                        <Link to={`/nation/${nation.id}`} key={nation.id}>
                            <div className={styles.block} style={{backgroundColor: nation.color}}>
                                <div className={styles.title}>
                                    {nation.title}
                                </div>
                                <img src={nation.pattern} alt="" className={styles.pattern}/>
                                <img src="/images/catalog/LINK_ARROW.svg" alt="" className={styles.arrow}/>
                            </div>
                        </Link>
                    ))
                }
            </div>
        </div>
    );
};

export default NationsPage;